.card4 {
    width: 300px;
    height: 300px;
    background: #13394f;
    border-radius: 36px;
    padding: 3px;
    position: relative;
    box-shadow: #604b4a30 0px 70px 30px -50px;
    transition: all 0.5s ease-in-out;
  }
  
  .card4 .mail {
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    background: transparent;
    border: none;
  }
  
  .card4 .mail svg {
    stroke: cyan;
    stroke-width: 3px;
  }
  
  .card4 .mail svg:hover {
    stroke: cyan;
  }
  
  .card4 .profile-pic {
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    border-radius: 29px;
    z-index: 1;
    border: 0px solid cyan;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0.2s, z-index 0.5s ease-in-out 0.2s;
  }
  
  .card4 .profile-pic img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    -o-object-position: 0px 0px;
    object-position: 0px 0px;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .card4 .profile-pic svg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0px 0px;
    object-position: 0px 0px;
    transform-origin: 45% 20%;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .card4 .bottom {
    position: absolute;
    bottom: 3px;
    left: 3px;
    right: 3px;
    background: cyan;
    top: 80%;
    border-radius: 29px;
    z-index: 2;
    box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  
  .card4 .bottom .content {
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem;
    height: 160px;
  }
  
  .card4 .bottom .content .name {
    display: block;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
  }
  
  .card4 .bottom .content .about-me {
    display: block;
    font-size: 0.9rem;
    color: white;
    margin-top: 1rem;
  }
  
  .card4 .bottom .bottom-bottom {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card4 .bottom .bottom-bottom .social-links-container {
    display: flex;
    gap: 1rem;
  }
  
  .card4 .bottom .bottom-bottom .social-links-container svg {
    height: 20px;
    fill: white;
    filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
  }
  
  .card4 .bottom .bottom-bottom .social-links-container svg:hover {
    fill: cyan;
    transform: scale(1.2);
  }
  
  .card4 .bottom .bottom-bottom .button {
    background: white;
    color: cyan;
    border: none;
    border-radius: 20px;
    font-size: 0.6rem;
    padding: 0.4rem 0.6rem;
    box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
  }
  
  .card4 .bottom .bottom-bottom .button:hover {
    background: cyan;
    color: white;
  }
  
  .card4:hover {
    border-top-left-radius: 55px;
  }
  
  .card4:hover .bottom {
    top: 20%;
    border-radius: 80px 29px 29px 29px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  }
  
  .card4:hover .profile-pic {
    width: 100px;
    height: 100px;
    aspect-ratio: 1;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: 3;
    border: 7px solid ;
    box-shadow: rgba(74, 95, 96, 0.188) 0px 5px 5px 0px;
    transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
  }
  
  .card4:hover .profile-pic:hover {
    transform: scale(1.3);
    border-radius: 0px;
  }
  
  .card4:hover .profile-pic img {
    transform: scale(2.5);
    -o-object-position: 0px 25px;
    object-position: 0px 25px;
    transition: all 0.5s ease-in-out 0.5s;
  }
  
  .card4:hover .profile-pic svg {
    transform: scale(2.5);
    transition: all 0.5s ease-in-out 0.5s;



    /* Glass glow - STARTS */
:root {
  --white08: rgba(255, 255, 255, 0.8);
  --white05: rgba(255, 255, 255, 0.5);
  --white009: rgba(255, 255, 255, 0.09);
  --white001: rgba(255, 255, 255, 0.01);
  --borderSize: 10px;
}
.bwGlow {
  border: black 1px solid;
  box-shadow: 0 0 0 calc(var(--borderSize) / 2) rgba(255, 255, 255, 0.05);
}

.bwGlow::before,
.bwGlow::after {
  content: "";
  position: absolute;
  left: calc((var(--borderSize) / 2) * -1);
  top: calc((var(--borderSize) / 2) * -1);
  background: linear-gradient(
    45deg,
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white009),
    var(--white05),
    var(--white08),
    var(--white05),
    var(--white009),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001),
    var(--white001)
  );
  background-size: 300%;
  width: calc(100% + var(--borderSize));
  height: calc(100% + var(--borderSize));
  animation: glassBorder 1.7s ease-in-out infinite;
}

.bwGlow::before {
  z-index: -1;
  box-shadow: 0 0 0 1 black;
}
.bwGlow::after {
  filter: blur(40px);
  z-index: -2;
  /*   opacity: 0.5; */
}

@keyframes glassBorder {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 150% 50%;
  }
}
/* Glass glow - ENDS */
  }