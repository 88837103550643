.container2 {
    width: 240px;
    height: 294px;
    perspective: 900px;
   
    
   
  }
  
  .card2 {
    height: 100%;
    width: 100%;
    background-color: aliceblue;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;
    border-radius: 2rem;
    padding: "100px";
  
  }
  
  .container2:hover > .card2 {
    cursor: pointer;
    transform: rotateY(180deg) rotateZ(180deg);
  }
  
  .front2, .back2 {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    position: absolute;
    box-shadow: 0 0 10px 2px rgba(50, 50, 50, 2.5);
    backface-visibility: hidden;
    color: aliceblue;
    background: linear-gradient(-135deg, 
    cyan, blue);
  }
  
  .front2, .back2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .back2 {
    transform: rotateY(180deg) rotateZ(180deg);
  }
  
  .back-heading2, .front-heading2 {
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
  }

  /**/

